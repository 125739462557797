// import countries from '@/list/countries'
// import { withdrawalFilterPayment } from '@/list/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'createdAt',
    label: 'Requested at',
    type: 'datetime',
  },
  {
    key: 'reporter.nickname',
    label: 'Reporter',
    type: 'profile',
    image: 'reporter.avatar',
    url: {
      callback(data) {
        // eslint-disable-next-line no-console
        return `/user/${data.reporterID}`
      },
    },
  },
  {
    key: 'user.nickname',
    label: 'Reported',
    type: 'profile',
    image: 'user.avatar',
    sortable: false,
    url: {
      callback(data) {
        return `/user/${data.userID}`
      },
    },
  },
  {
    key: 'entityType',
    label: 'Entity type',
    type: 'badge',
    map: {
      user: 'primary',
      post: 'success',
      service: 'dark',
      chat: 'info',
      showreel: 'warning',
    },
    filter: {
      key: 'entityType',
      type: 'select',
      options: [
        { value: 'user', text: 'User' },
        { value: 'post', text: 'Post' },
        { value: 'chat', text: 'Chat' },
        { value: 'service', text: 'Service' },
        { value: 'showreel', text: 'Show Reel' },
        { value: 'mobileUser', text: 'Mobile User' },
        { value: 'mobileChat', text: 'Mobile Chat' },
        { value: 'mobilePost', text: 'Mobile Post' },
        { value: 'mobileService', text: 'Mobile Service' },
        { value: 'mobileShowreel', text: 'Mobile Show Reel' },
      ],
    },
  },
  {
    key: 'entityID',
    label: 'Entity id',
    type: 'id',
    url: {
      callback(data) {
        if (data.entityType === 'user') {
          return `/user/${data.entityID}`
        }
        if (data.entityType === 'post' || data.entityType === 'mobilePost') {
          return `${process.env.VUE_APP_CLIENT_URL}/post/${data.entityID}`
        }
        if (data.entityType === 'service' || data.entityType === 'mobileService') {
          return `/service/${data.entityID}`
        }
        return '/report'
      },
    },
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    sortable: false,
    map: {
      approved: 'success',
      pending: 'warning',
      rejected: 'danger',
    },
  },
  {
    key: 'remark',
    label: 'Remark',
    visibility: ['view'],
  },
  {
    key: 'type',
    label: 'Category',
    type: 'dot',
    map: {},
    filter: {
      key: 'type',
      type: 'select',
      options: [
        { value: 'Spam', text: 'Spam' },
        { value: 'Offensive', text: 'Offensive' },
        { value: 'fakeAccount', text: 'Fake Account' },
        { value: 'privacyViolation', text: 'Privacy Violation' },
        { value: 'report_modal.others', text: 'Report Modal' },
        { value: 'Order dispute', text: 'Order dispute' },
        { value: 'Others', text: 'Others' },
      ],
    },
  },
  // all value is in usd
  {
    key: 'updatedAt',
    label: 'Updated at',
    type: 'datetime',
    visibility: ['view'],
  },
]
