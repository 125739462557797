export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'code',
    label: 'Name',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'gold',
  },
  {
    key: 'user.nickname',
    label: 'User',
    type: 'profile',
    sortable: false,
    image: 'user.avatar',
    url: {
      callback(data) {
        return data.user && data.userID ? `/user/${data.userID}` : null
      },
    },
  },
  {
    key: 'totalUsage',
    label: 'Total Usage',
  },
  {
    key: 'status',
    label: 'Status',
    type: 'dot',
    map: {
      used: 'secondary',
      available: 'success',
    },
  },
  {
    key: 'startDate',
    label: 'Start Date',
    type: 'datetime',
  },
  {
    key: 'endDate',
    label: 'End Date',
    type: 'datetime',
  },

]
